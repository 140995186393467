export const typesOfProjects = [
    {
        projectType: "Residential Projects",
        answer: "Tenetur ullam rerum ad iusto possimus sequi mollitia dolore sunt quam praesentium. Tenetur ullam rerum ad iusto possimus sequi mollitia dolore sunt quam praesentium.Tenetur ullam rerum ad iusto possimus sequi mollitia dolore sunt quam praesentium.",
    },
    {
        projectType: "Commercial Projects",
        answer: "Aperiam ab atque incidunt dolores ullam est, earum ipsa recusandae velit cumque. Aperiam ab atque incidunt dolores ullam est, earum ipsa recusandae velit cumque.",
    },
    {
        projectType: "Educational Projects",
        answer: "Blanditiis aliquid adipisci quisquam reiciendis voluptates itaque.",
    },
    {
        projectType: "Entertainment Projects",
        answer: "Blanditiis aliquid adipisci quisquam reiciendis voluptates itaque.",
    },
    {
        projectType: "Healthcare and Social Projects",
        answer: "Blanditiis aliquid adipisci quisquam reiciendis voluptates itaque.",
    },
    {
        projectType: "Road & Bridge Projects",
        answer: "Blanditiis aliquid adipisci quisquam reiciendis voluptates itaque.",
    },
    {
        projectType: "Geotechnical Projects",
        answer: "Blanditiis aliquid adipisci quisquam reiciendis voluptates itaque.",
    }
];